footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .left-side {
            margin-top: 20px;
            .text-copyright {
                margin-top: 75px;
                .copyright {
                    font-weight: normal;
                    font-size: 14px;
                    color: $color-2;
                    margin-bottom: 15px;
                }
            }
        }

        .center-side {
            .bloc {
                h3 {
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: $color-2;
                    text-transform: initial;
                    padding-bottom: 30px;
                }
                h4, a {
                    font-family: $font-family-2;
                    font-weight: normal;
                    font-size: 16px;
                    color: $color-2;
                }
                a {
                    border-bottom: 1px solid $color-2;
                }
            }
        }
        @media screen and (max-width: 1600px){
            .center-side .bloc.mx16 {
                margin-left: 4rem;
                margin-right: 4rem;
            }
            @media screen and (max-width: 1450px){
                .center-side .bloc.mx16 {
                    margin-left: 3rem;
                    margin-right: 3rem;
                }
               @media screen and (max-width: 1330px){
                        flex-direction: column;
                        align-items: initial;
                        .center-side {
                            padding: 50px 0;
                            .bloc.mx16 {
                                margin-left: 4rem;
                                margin-right: 4rem;
                            }
                        }
                        .left-side {
                            margin-top: 0;
                            .text-copyright {
                                margin-top: 25px;
                            }
                        }
                        @media screen and (max-width: $size-sm-max){
                            .center-side {
                                flex-direction: column;
                                padding: 50px 0;
                                .bloc {
                                    h3 {
                                        padding-bottom: 10px;
                                        font-size: 1.3rem;
                                    }
                                    a {
                                        border-bottom: none;
                                    }
                                }
                                .bloc.mx16 {
                                    margin: 35px 0;
                                }
                            }
                        }
                    }
                
            }
        }
    }
    @media screen and (max-width: 1330px){
        height: 550px;
        @media screen and (max-width: $size-sm-max){
            height: 700px;
        }
    }
}
