.btn {
    background-color: $color-1;
    border: 1 solid $color-1;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 190px;
    height: 80px;

    font-weight: normal;
    font-size: 16px;
    color: $color-3;
    text-transform: uppercase;
    letter-spacing: 3.2px;
    transition: all 0.3s ease-in;
   
    &:hover {
        transition: all 0.3s ease-in;
        background-color: $color-white;
        color: $color-3;
        border: 1px solid $color-1;
    }
}
