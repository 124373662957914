$input_height: 50px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-primary;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, textarea.form-control {
        height: $input_height;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: $input_back_color;
        border-bottom: none;
        border-radius: $input_border_radius;
    }
    .form-control {
        border: none;
    }
    input.form-control {
        border-bottom: 1px solid $color-3;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        &.border {
            border-bottom: none;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
            background: #F4F4F4;
            width: 260px;
            @media screen and (max-width: $size-xs-max) {
                width: 100%;
            }
        }
    }
    textarea.form-control {
        border: 1px solid #707070;
    }
    input::placeholder, textarea::placeholder {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-20;
        color: #05060176;
        padding-left: 0 !important;
    }
    label {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-20;
        color: #070200;
        padding-bottom: 20px;
        @media screen and (max-width: $size-sm-max) {
            font-size: 15px;
        }
    }
    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .clear-both {
        clear: both;
    }



    //// checkboxes and radiobuttons style ///////

    label.item {
        display: flex;
        position: relative;
        padding-right: 85px;
        margin-right: 100px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        text-transform: inherit;
        height: 40px;
        align-items: center;
        margin-bottom: 0;
        transition: 0.3s;

        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-20;
        color: #070200;
        padding-bottom: 0;
        text-transform: initial;
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        @media screen and (max-width: $size-xs-max) {
            padding-right: 0;
            margin-right: 0;
            height: 75px;
            padding-bottom: 35px;
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
        background-color: #F4F4F4;
        border: none;
        border-radius: 0;
        transition: 0.3s;
    }

    /* On mouse-over, add a grey background color */
    .checkmark:hover input ~ .checkmark {
        background-color: #b8031b;
        transition: 0.3s;
    }

    /* When the checkbox is checked, add a blue background */
    .item input:checked ~ .checkmark {
        background-color: $color-1;
        border: 2px solid $color-1;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .item input:checked ~ .checkmark:after {
        display: none;
    }
    .item .checkmark:after {
        left: 10px;
        top: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .checkbox , .radio {
        display: flex;
        cursor: pointer;
        width: 100%;
        & > span {
            color: #024b94;
            padding: 0.5rem 0.25rem;
            margin-right: auto;
        }
        & >  input {
            height: 25px;
            width: 25px;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            outline: none;
            transition-duration: 0.3s;
            background-color: $input_back_color;
            cursor: pointer;
            position: inherit!important;
        }
        & > input:checked {
            border: 3px solid $input_back_color;
            background-color: #024b94;
        }
    }

    .radio > input {
        border-radius: 50%;
    }

//// End checkboxes and radiobuttons style///////

    textarea {
        //margin: 2em 0;
        height: 350px !important;
        @media screen and (max-width: 1450px) {
            height: 250px !important;
        }
    }
    .buttons {
        display: flex;
        justify-content: initial;
        flex-wrap: wrap;
        margin: 0 0 50px 0;
        padding: 0;
    }

}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
