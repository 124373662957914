// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck, a.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-link {
    background: $color-1;
    width: 260px;
    height: 80px;
    margin-top: 25px;
    border-radius: 10px;
    transition: 0.3s;
    h5 {
        font-family: $font-family-1;
        font-weight: normal;
        font-size: 16px;
        text-transform: uppercase;
        color: $color-3;
        letter-spacing: 3.2px;
    }
    &:hover {
        background: $color-white;
        transition: 0.3s;
    }
    @media screen and (max-width: $size-xs-max){
        width: 200px;
        height: 75px;
    }
}





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 55%;
            left: 50%;
            width: 100%;
            transform: translateX(-50%);
            z-index: $z-index-slide-caption;
            padding: 0 5%;
            h2 {
                font-weight: bold;
                font-size: $font-size-60;
                color: #F4F4F4;
                padding-bottom: 25px;
            }
            p {
                font-size: $font-size-24;
                width: 67%;
                color: #F4F4F4 !important;
                margin-bottom: 50px;
            }
            &.services {
                top: 55% !important;
                h2 {
                    font-weight: 500;
                    font-size: $font-size-46;
                    color: #F4F4F4;
                }
                @media screen and (max-width: 1450px) {
                    h2 {
                        font-size: 2rem;
                    }
                    @media screen and (max-width: 1200px) {
                        h2 {
                            font-size: 1.8rem;
                        }
                        @media screen and (max-width: $size-sm-max) {
                            h2 br {
                                display: none;
                            }
                            @media screen and (max-width: 360px) {
                                h2 {
                                    font-size: 1.7rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1700px) {
            &_caption {
                top: 49%;
                p {
                    width: 75%;
                }
            }
            @media screen and (max-width: 1600px) {
                &_caption {
                    top: 53%;
                    p {
                        width: 73%;
                        font-size: 1.1rem;
                    }
                }
                @media screen and (max-width: 1450px) {
                    &_caption {
                        top: 45%;
                    }
                    @media screen and (max-width: 1325px) {
                        &_caption {
                            p {
                                width: 80%;
                            }
                        }
                        @media screen and (max-width: 1105px) {
                            &_caption {
                                top: 41%;
                            }
                            @media screen and (max-width: 1024px) {
                                &_caption {
                                    top: 39%;
                                    p {
                                        width: 100%;
                                        margin-bottom: 35px;
                                    }
                                }
                                @media screen and (max-width: $size-sm-max) {
                                    &_caption {
                                        top: 30%;
                                        p {
                                            font-size: 1rem;
                                        }
                                    }
                                    @media screen and (max-width: $size-xs-max) {
                                        &_caption {
                                            top: 45%;
                                            h2 {
                                                font-size: 2.8rem;
                                            }
                                            a.btn-link {
                                                width: 230px;
                                            }
                                        }
                                        @media screen and (max-width: 375px) {
                                            &_caption {
                                                top: 40%;
                                                h2 {
                                                    font-size: 2.6rem;
                                                }
                                            }
                                            @media screen and (max-width: 360px) {
                                                &_caption {
                                                    top: 36%;
                                                    h2 {
                                                        font-size: 2.5rem;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-texte-image {
    padding: 150px 0 0 0;
    .text-side {
        background: #F4F4F4;
        width: 50%;
        padding: 50px 5%;
        h3 {
            font-weight: normal;
            font-size: $font-size-46;
            color: $color-3;
            text-transform: initial;
            padding-bottom: 60px;
        }
    }
    .right-side {
        width: 50%;
        background: url("../images/accueil_section01_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1500px) {
        .text-side {
            h3 {
                font-size: 2rem;
                padding-bottom: 50px;
            }
        }
        @media screen and (max-width: 1200px) {
            padding: 100px 5% 0 5%;
            flex-direction: column-reverse;
            .right-side {
                width: 70%;
                margin: auto;
            }
            .text-side {
                width: 70%;
                margin: auto;
                padding: 60px 35px;
            }
            @media screen and (max-width: $size-sm-max) {
                .text-side, .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .right-side {
                        background: url("../images/accueil_section01_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                    .text-side {
                        padding: 60px 25px;
                        h3 {
                            font-size: 1.85rem;
                            padding-bottom: 35px;
                        }
                    }
                }       
            }
        }
    }
}

#section-images {
    padding: 125px 5% 100px 5%;
    .title {
        padding-bottom: 100px;
        h3 {
            font-weight: normal;
            font-size: $font-size-60;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 25px;
            border-bottom: 1px solid $color-1;
        }
    }
    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .accordion__item {
            .image {
                font-size: 1rem;
                display: inline-block;
                vertical-align: top;
                min-height: 600px;
                box-sizing: border-box;
                position: relative;
                outline: none;
                // border: 1px solid black;
                width: 100%;
                overflow: hidden;
            }
            .text {
                margin-top: 25px;
                h4 {
                    font-weight: 600;
                    font-size: $font-size-18;
                    text-transform: uppercase;
                    color: #070200;
                    line-height: 1.5;
                }
            }
            &:nth-child(1) {
                .image {
                    background-image: url("../images/accueil_slide_01.jpg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
            &:nth-child(2) {
                .image {
                    background-image: url("../images/accueil_slide_02.jpg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
            &:nth-child(3) {
                .image {
                    background-image: url("../images/accueil_slide_03.jpg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
            &:nth-child(4) {
                .image {
                    background-image: url("../images/accueil_slide_04.jpg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            }
        }
    }
    a.btn-link {
        margin-top: 80px;
        width: 295px;
        border: 1px solid $color-1;
        &:hover {
            border: 1px solid $color-1;
        }
    }
    @media screen and (max-width: 1500px) {
        .section-images.accordion .accordion__item .text h4 {
            font-size: 0.8rem;
        }
        @media screen and (max-width: $size-md-max) {
            .title {
                h3 {
                    font-size: 2.5rem;
                }
            }
            @media screen and (max-width: 1125px) {
                .section-images.accordion .accordion__item .text h4 {
                    text-align: center;
                    font-size: 0.7rem;
                }
                @media screen and (max-width: $size-xs-max) {
                    .title {
                        padding-bottom: 50px;
                        h3 {
                            font-size: 2.5rem;
                            text-align: center;
                        }
                    }
                    @media screen and (max-width: 375px) {
                        .title {
                            h3 {
                                font-size: 2.3rem;
                            }
                        }
                    }
                }
            }
        }  
    }
}

#section-title-images {
    padding: 100px 5%;
    background: $color-2;
    h3 {
        font-weight: normal;
        font-size: $font-size-46;
        color: $color-3;
        text-transform: initial;
        padding-bottom: 80px;
    }
    .section-images {
        .image {
            width: 49%;
        }
    }
    @media screen and (max-width: 1400px) {
        h3 {
            font-size: 2rem;
        }
        @media screen and (max-width: 1100px) {
            h3 {
                font-size: 1.8rem;
            }
            @media screen and (max-width: $size-sm-max) {
                h3 {
                    font-size: 2.35rem;
                    text-align: center;
                    line-height: 1.3;
                }
                @media screen and (max-width: $size-xs-max) {
                    h3 {
                        font-size: 1.85rem;
                        padding-bottom: 60px;
                    }
                    .section-images {
                        flex-direction: column;
                        .image {
                            width: 100%;
                            &:first-child {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-title-logo {
    padding: 125px 5%;
    .left-side {
        h3 {
            font-weight: normal;
            font-size: $font-size-46;
            color: $color-3;
            line-height: 1.3;
            text-transform: initial;
        }
    }
    @media screen and (max-width: 905px) {
        flex-direction: column;
        .left-side {
            padding-bottom: 50px;
            text-align: center;
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-texte-image.services {
    padding: 80px 0 0 0;
    .text-side {
        width: 40%;
        margin-right: 15px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            padding-bottom: 35px;
        }
    }
    .right-side {
        width: 60%;
        background: url("../images/services_section01_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1200px) {
        padding: 80px 5% 0 5%;
        .right-side {
            width: 75%;
        }
        .text-side {
            width: 75%;
            margin: 15px auto 0 auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .right-side, .text-side {
                width: 100%;
            }
        }
    }
}

#section-image-text {
    padding: 100px 0;
    .left-side {
        width: 57%;
        background: url("../images/services_section02_img01.jpg") no-repeat;
        background-size: cover;
        margin-right: 15px;
    }
    .right-side {
        width: 43%;
        padding: 75px 50px;
        background: #F4F4F4;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #070200;
            padding-bottom: 40px;
        }
        .bloc {
            p {
                font-size: $font-size-24;
                color: #231F20;
                line-height: 1.2;
            }
            a {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-24;
                color: #231F20;
                text-decoration: underline !important;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 100px 5%;
        flex-direction: column;
        .left-side {
            width: 75%;
            margin: 0 auto;
        }
        .right-side {
            width: 75%;
            margin: 15px auto 0 auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side, .right-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    background: url("../images/services_section02_img01_m.jpg") no-repeat;
                    background-size: cover;
                }
                .right-side {
                    padding: 50px 15px;
                    h4 {
                        padding-bottom: 30px;
                    }
                    .bloc {
                        margin-left: -13px;
                    }
                }
            }
        }
    }
}

#section-background {
    background: #05060112;
    padding: 100px 5%;
    margin-bottom: 80px;
    .left-side {
        width: 45%;
        padding-right: 130px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #070200;
            line-height: 1.3;
            padding-bottom: 45px;
        }
    }
    .right-side {
        width: 55%;
    }
    @media screen and (max-width: 1700px) {
        .left-side {
            padding-right: 80px;
        }
        @media screen and (max-width: 1510px) {
            .left-side h4 br {
                display: none;
            }
            @media screen and (max-width: $size-md-max) {
                .left-side {
                    h4 {
                        padding-bottom: 30px;
                    }
                }
                @media screen and (max-width: 1200px) {
                    flex-direction: column-reverse;
                    .right-side {
                        width: 75%;
                        margin: 0px auto 50px auto;
                    }
                    .left-side {
                        width: 100%;
                        padding: 0;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        padding: 75px 5%;
                        .right-side {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* PRODUITS
*******************************************************************************/

#section-intro-produits {
    background: #fff;
    margin: -150px 5% 75px 5%;
    padding: 75px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
    h3 {
        font-weight: 500;
        font-size: $font-size-46;
        color: $color-3;
        text-align: center;
        line-height: 1.3;
        text-transform: initial;
    }
    a.btn-link {
        margin: 40px auto 0 auto;
        width: 305px;
        border: 1px solid $color-1;
        &:hover {
            border: 1px solid $color-1;
        }
    }
    @media screen and (max-width: 1715px){
        h3 {
            font-size: 2.15rem;
        }
        @media screen and (max-width: 1610px){
            h3 {
                font-size: 2rem;
            }
            @media screen and (max-width: 1500px){
                h3 {
                    br {
                        display: none;
                    }
                }
                @media screen and (max-width: $size-md-max){
                    padding: 75px 50px;
                    @media screen and (max-width: 1024px){
                        padding: 60px 50px;
                        h3 {
                            font-size: 1.8rem;
                        }
                        @media screen and (max-width: $size-xs-max){
                            padding: 60px 25px;
                            h3 {
                                font-size: 1.8rem;
                                text-align: center;
                            }
                            a.btn-link {
                                margin: 65px auto 0 auto;
                                width: 275px;
                            }
                            @media screen and (max-width: 360px){
                                h3 {
                                    font-size: 1.65rem;
                                }
                                a.btn-link {
                                    width: 250px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-produits {
    padding: 0 5% 50px 5%;
    .produits {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: repeat(1, 1fr);     
        }
        .bloc {
            padding-bottom: 100px;
            .text {
                padding-top: 30px;
                h3 {
                    font-weight: bold;
                    font-size: $font-size-30;
                    color: $color-black;
                    text-transform: initial;
                    padding-bottom: 20px;
                }
                p {
                    line-height: 1.5;
                    width: 80%;
                }
            }
            @media screen and (max-width: $size-md-max){
                .text p {
                    width: 95%;
                }
                @media screen and (max-width: $size-sm-max){
                    padding-bottom: 50px;
                    .text p {
                        width: 100%;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 75px 5%;
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
        .img-responsive {
            width: 100%;
        }
    }
    #blocIntro_promotions {
        h3 {
            color: $color-1;
            line-height: 1.3;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-info {
    padding: 50px 5% 0 5%;
    .left-side {
        width: 35%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-3;
            text-transform: initial;
        }
    }
    .right-side {
        width: 65%;
        .bloc {
            h4 {
                font-weight: bold;
                font-size: $font-size-30;
                color: $color-black;
                padding-bottom: 25px;
            }
            h5 {
                font-family: $font-family-2;
                font-weight: bold;
                font-size: $font-size-20;
                color: $color-3;
                margin-top: 35px;
                padding-bottom: 25px;
            }
            p, a {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-20;
                color: $color-3;
            }
            a {
                border-bottom: 1px solid $color-3;
                width: max-content;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .left-side {
            width: 30%;
        }
        .right-side {
            width: 70%;
        }
        @media screen and (max-width: 1460px) {
            .left-side {
                width: 25%;
            }
            .right-side {
                width: 75%;
            }
            @media screen and (max-width: 1350px) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 75px 0;
                }
                .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .right-side {
                        flex-direction: column;
                        .bloc {
                            h4, h5 {
                                padding-bottom: 15px;
                            }
                        }
                        .bloc:nth-child(2) {
                            padding: 35px 0;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .left-side {
                            h3 {
                                font-size: 2rem;
                            }  
                        }
                        @media screen and (max-width: 375px) {
                            .right-side a.mail {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-formulaire {
    padding: 100px 5% 50px 5%;
    overflow: hidden;
    @media screen and (max-width: $size-xs-max) {
        .col-sm-6.pl0, .col-sm-6.pr0 {
            padding: 0;
        }
        .col-xs-4 {
            width: 100%;
            padding: 0;
        }
        .list-checkBox {
            flex-direction: column;
            align-items: initial;
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
    

}
